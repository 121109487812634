<script setup>
import LabelledField from "@/components/utils/LabelledField";
import { getFormattedMoney, stringNotEmpty } from "@/utils/functions";
import { fieldRequired, isNumber, minValue } from "@/utils/input_validation.js";
import {
  useGenerateRatesBill,
  useGetLastPropertyLog,
} from "../properties_business_logic";
import {
  countyIsKirinyaga,
  useSetToUpperCase,
  useRouterFromInstance,
} from "@/utils/vue_helpers";
import { computed, onMounted, ref, watchEffect } from "vue";
import { setSnackbar } from "@/components/utils/snackbar/logic";
import { routes } from "@/router/routes";

const props = defineProps({
  property: {
    type: Object,
    default: null,
  },
  dialogOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:dialogOpen", "ratesBillGenerated"]);

const lastPropertyLog = useGetLastPropertyLog();

const rates = ref(null);

const amountToPayModel = ref("");

watchEffect(() => {
  lastPropertyLog.mutate(
    { property: props.property },
    {
      onSuccess(lastPropertyLog) {
        rates.value = lastPropertyLog;
      },
    }
  );
});

const remarksModel = ref("");

useSetToUpperCase({
  textRef: remarksModel,
  setText: (newText) => {
    remarksModel.value = newText;
  },
});

const { generateRatesBill, generatingRatesBill } = useGenerateRatesBill();

const innerDialogOpen = computed({
  get() {
    return props.dialogOpen;
  },
  set(newVal) {
    if (!generatingRatesBill.value) {
      emit("update:dialogOpen", newVal);
    }
  },
});

const propertyHasWaiver = computed(() => {
  return !!props.property.waiver;
});

const waiverAmounts = computed(() => {
  const waiver = props.property.waiver;

  let amountWaived = 0;
  let totalAfterWaiver = 0;
  if (propertyHasWaiver.value && !lastPropertyLog.isLoading.value) {
    amountWaived = Math.ceil(
      rates.value.accumulated_penalty * (waiver.waiver_percentage / 100)
    );
    totalAfterWaiver = Math.ceil(rates.value.current_balance - amountWaived);
  }
  return {
    amountWaived,
    totalAfterWaiver,
  };
});

const waiverWillNotApplyToUser = computed(() => {
  const amountToPay = amountToPayModel.value;
  const amountToPayNotEmpty = stringNotEmpty(amountToPay);
  if (propertyHasWaiver.value && amountToPayNotEmpty) {
    const amountToPayAsNumber = Number(amountToPay);
    const outstandingAmount = Number(waiverAmounts.value.totalAfterWaiver);
    const outstandingAmountNotPaidInFull =
      amountToPayAsNumber < outstandingAmount;
    return outstandingAmountNotPaidInFull;
  }
  return false;
});

const sections = computed(() => {
  if (lastPropertyLog.isLoading.value) {
    return null;
  }

  const firstSection = {
    "Annual Rate": getFormattedMoney(rates.value?.annual_rates),
    "Ground Rent": getFormattedMoney(rates.value?.ground_rent),
    "Other Charges": getFormattedMoney(rates.value?.other_charges),
    "Accumulated Penalty": getFormattedMoney(rates.value?.accumulated_penalty),
  };
  if (propertyHasWaiver.value) {
    firstSection["Amount Waived"] = getFormattedMoney(
      waiverAmounts.value.amountWaived
    );
    firstSection["Total Outstanding After Waiver"] = getFormattedMoney(
      waiverAmounts.value.totalAfterWaiver
    );
  }

  return {
    firstSection,
    secondSection: {
      "Annual Rate Arrears": getFormattedMoney(
        rates.value?.annual_rates_arrears
      ),
      "Ground Rent Arrears": getFormattedMoney(
        rates.value?.ground_rend_arrears
      ),
      "Other Charges Arrears": getFormattedMoney(
        rates.value?.other_charges_arrears
      ),
      "Total Outstanding": getFormattedMoney(rates.value?.current_balance),
    },
  };
});

function shouldBeBold(title) {
  const isTotalOutstanding = title === "Total Outstanding";
  if (isTotalOutstanding) {
    return true;
  }

  const isTotalAfterWaiver = title === "Total Outstanding After Waiver";
  if (isTotalAfterWaiver) {
    return true;
  }

  return false;
}

const formRef = ref(null);
async function onGenerateRatesBillFormSubmitted() {
  const formValid = formRef.value.validate();
  if (formValid) {
    const bill = await generateRatesBill({
      property: props.property,
      amount: amountToPayModel.value,
      // remarks: remarksModel.value
    });
    setSnackbar({
      text: "Rates Bill Generated",
      color: "success",
      open: true,
    });
    // emit("ratesBillGenerated", bill);
    innerDialogOpen.value = false;
    goToPaymentSection(bill);
  }
}

const { router } = useRouterFromInstance();

function goToPaymentSection(bill) {
  const paymentRoute = routes.payment.withQuery({
    billNumbersToPayAsString: bill.bill_number,
    directPayment: true,
    billsToPay: [bill],
  });
  router.push(paymentRoute);
}

const remarksFieldRules = computed(() => {
  if (countyIsKirinyaga) {
    return [];
  }
  return [];
});

const minimumPaymentAmount = computed(() => {
  if (lastPropertyLog.isLoading.value) {
    return 0;
  }
  if (propertyHasWaiver.value) {
    return Math.ceil(waiverAmounts.value.totalAfterWaiver);
  }
  return Math.ceil(rates.value?.current_balance ?? 0);
});

const shouldSetDefaultValueOnAmountToPay = computed(() => {
  return minimumPaymentAmount.value > 0;
});

watchEffect(() => {
  formRef.value?.resetValidation();
  amountToPayModel.value = shouldSetDefaultValueOnAmountToPay.value
    ? minimumPaymentAmount.value
    : "";
});

const formValid = ref(false);
</script>

<template>
  <v-dialog
    v-model="innerDialogOpen"
    :persistent="generatingRatesBill"
    max-width="1000"
  >
    <v-card>
      <v-toolbar color="primary" flat>
        <v-toolbar-title class="white--text">
          Pay Property Rates
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="innerDialogOpen = false"
                ><v-icon class="white--text">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="formRef"
          v-model="formValid"
          @submit.prevent="onGenerateRatesBillFormSubmitted"
        >
          <v-container fluid class="pa-0 mt-4">
            <v-row v-if="lastPropertyLog.isLoading.value">
              <v-col cols="6" v-for="num in 2" :key="num">
                <v-simple-table>
                  <tbody>
                    <tr v-for="num in 4" :key="num">
                      <td><v-skeleton-loader type="text" /></td>
                      <td><v-skeleton-loader type="text" /></td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col
                cols="6"
                v-for="(section, sectionName) in sections"
                :key="sectionName"
              >
                <v-simple-table>
                  <tbody>
                    <tr v-for="(value, title) in section" :key="title">
                      <template v-if="!shouldBeBold(title)">
                        <td>{{ title }}</td>
                        <td class="text-right">{{ value }}</td>
                      </template>
                      <template v-else>
                        <td class="font-weight-bold">{{ title }}</td>
                        <td class="text-right font-weight-bold">{{ value }}</td>
                      </template>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row justify="start" align="start">
              <v-col v-if="false" cols="12" md="6">
                <LabelledField label="Remarks">
                  <v-textarea
                    outlined
                    dense
                    auto-grow
                    resizable
                    class="text-field-background"
                    hide-details="auto"
                    v-model="remarksModel"
                    :rules="remarksFieldRules"
                    rows="2"
                    persistent-hint
                    :hint="`If you have any information you want to add to the property rates bill you can enter it here`"
                  />
                </LabelledField>
              </v-col>

              <v-col cols="12" md="6">
                <LabelledField required>
                  <template #label>
                    <v-tooltip top offset-y>
                      <template #activator="{ on, attrs }">
                        <div class="d-flex justify-start">
                          <div v-on="on" v-bind="attrs">
                            <span>Amount To Pay</span>
                            <v-icon small class="mx-1"
                              >mdi-information-outline</v-icon
                            >
                            <span class="error--text">{{ ` *` }}</span>
                          </div>
                        </div>
                      </template>
                      <span class="text-body-2">
                        The amount paid can be more than the indicated
                        <span class="font-weight-bold">Total Outstanding</span>
                        or
                        <span class="font-weight-bold"
                          >Total Outstanding After Waiver</span
                        >
                      </span>
                    </v-tooltip>
                  </template>

                  <v-text-field
                    class="text-field-background"
                    outlined
                    dense
                    v-model="amountToPayModel"
                    clearable
                    :rules="[
                      fieldRequired,
                      isNumber,
                      minValue(
                        Math.max(minimumPaymentAmount, 1),
                        `Your payment must be more than or equal to ${Math.max(
                          minimumPaymentAmount,
                          1
                        )} Ksh`
                      ),
                    ]"
                    hide-details="auto"
                  >
                  </v-text-field>
                </LabelledField>
              </v-col>
            </v-row>

            <v-row v-if="propertyHasWaiver && waiverWillNotApplyToUser">
              <v-col cols="auto">
                <div class="d-flex error--text">
                  <v-icon color="error">mdi-alert-circle</v-icon>
                  <span class="ml-2"
                    >For the waiver to apply, the amount you pay must be equal
                    to or more than the
                    <span class="font-weight-bold text-decoration-underline"
                      >Total Outstanding After Waiver</span
                    >
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="auto">
                <v-btn
                  color="secondary"
                  class="text-none"
                  depressed
                  type="submit"
                  :loading="generatingRatesBill"
                  :disabled="!formValid"
                >
                  Pay <v-icon right>mdi-cash</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
