<script setup>
import ViewItemSectionTitle from "@/components/utils/ViewItemSectionTitle.vue";
import {
  getFormattedMoney,
  convertDateStringToDMY,
} from "@/utils/functions.js";
import { computed } from "vue";

const props = defineProps({
  property: {
    type: Object,
    default: null,
  },
});

const propertyInformationItems = computed(() => {
  const property = props.property;
  return {
    PRN: property.prn,
    "Block/Plot Number": `${property.block_no}/${property.plot_no}`,
    "LR Number": property.lr_no,
    "Property Document Type": property.property_doc_type.name,
    "Minute Number": property.minute_no ?? "N/A",
    "Property Document Number": property?.document_no ?? null,
    "Form Number": property?.form_no ?? null,
    Subcounty: property.sub_county.sub_county_name,
    Ward: property.ward.ward_name,
    Town: property?.town?.town_name ?? null,
    "Physical Location": property.physical_location,
    "Property Ownership": property?.property_ownership?.ownership ?? null,
    "Mother Plot PRN": property?.mother_plot?.prn ?? null,
    Status: property?.work_flow_status?.status_name ?? null,
  };
});

const propertyValuationItems = computed(() => {
  const property = props.property;
  return {
    "Annual Rate": getFormattedMoney(property.annual_rate),
    "Annual Ground Rent": getFormattedMoney(property.annual_ground_rent),
    "Last Payment Amount": getFormattedMoney(property.last_payment_amount),
    "Last Payment Date": convertDateStringToDMY(property.last_payment_date),
  };
});

const propertyDetailItems = computed(() => {
  const property = props.property;
  return {
    column1: {
      PRN: property.prn,
      "Block/Plot Number": `${property.block_no}/${property.plot_no}`,
      "LR Number": property.lr_no,
      "Property Document Type": property.property_doc_type.name,
      "Minute Number": property.minute_no ?? "N/A",
      "Property Document Number": property?.document_no ?? null,

      Subcounty: property.sub_county.sub_county_name,
      Ward: property.ward.ward_name,
      Town: property?.town?.town_name ?? null,
    },
    column2: {
      "Form Number": property?.form_no ?? null,
      "Physical Location": property.physical_location,
      "Property Ownership": property?.property_ownership?.ownership ?? null,
      "Mother Plot PRN": property?.mother_plot?.prn ?? null,
      Status: property?.work_flow_status?.status_name ?? null,
      "Annual Rate": getFormattedMoney(property.annual_rate),
      "Annual Ground Rent": getFormattedMoney(property.annual_ground_rent),
      "Last Payment Amount": getFormattedMoney(property.last_payment_amount),
      "Last Payment Date": property.last_payment_date
        ? convertDateStringToDMY(property.last_payment_date)
        : "N/A",
    },
  };
  //   return {
  //     "Property Information": propertyInformationItems.value,
  //     "Property Valuation": propertyValuationItems.value,
  //   };
});
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="auto">
        <ViewItemSectionTitle> Property Details</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(propertyDetailItem, title) in propertyDetailItems"
        :key="title"
        cols="12"
        md="6"
      >
        <v-container fluid class="pa-0">
          <v-row justify="center">
            <v-col cols="12">
              <v-simple-table dense>
                <template #default>
                  <tbody>
                    <template v-for="(value, title) in propertyDetailItem">
                      <tr>
                        <td class="font-weight-bold">{{ title }}</td>
                        <td>{{ value ?? 'N/A' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
