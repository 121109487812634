var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.ViewItemSectionTitle,[_vm._v("Bill")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
          { text: 'Item', value: 'item' },
          { text: 'Bill Number', value: 'billNumber' },
          { text: 'Amount', value: 'amount' },
          { text: 'Download', value: 'download', action: true },
          { text: 'Pay', value: 'pay', action: true },
        ],"items":_setup.propertyBills,"emptyText":"No Property Bills Found"},scopedSlots:_vm._u([{key:"item.amount",fn:function({ item: propertyBill }){return [_vm._v(" "+_vm._s(_setup.getFormattedMoney(propertyBill.amount))+" ")]}},{key:"item.download",fn:function({ item: propertyBill }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadBill,"inTable":""},on:{"click":function($event){return _vm.$emit('printBillClicked', {
                billId: propertyBill.billId,
                billNumber: propertyBill.billNumber,
              })}}})]}},{key:"item.pay",fn:function({ item: propertyBill }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payBillClicked', propertyBill)}}})]}}])}),(false)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Item")]),_c('th',[_vm._v("Bill Number")]),_c('th',[_vm._v("Amount")]),_c('th',{staticClass:"text-right"},[_vm._v("Actions")])])]),_c('tbody',[(_setup.arrayNotEmpty(_setup.propertyBills))?_vm._l((_setup.propertyBills),function(propertyBill){return _c('tr',[_c('td',[_vm._v(_vm._s(propertyBill.item))]),_c('td',[_vm._v(_vm._s(propertyBill.billNumber))]),_c('td',[_vm._v(_vm._s(_setup.getFormattedMoney(propertyBill.amount)))]),_c('td',{staticClass:"text-right"},[_c(_setup.ActionsContainer,[_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadBill,"inTable":""},on:{"click":function($event){return _vm.$emit('printBillClicked', {
                          billId: propertyBill.billId,
                          billNumber: propertyBill.billNumber,
                        })}}}),_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payBillClicked', propertyBill)}}})],1)],1)])}):_c('tr',[_c('td',{attrs:{"colspan":"4","align":"center"}},[_vm._v("No Bills")])])],2)]},proxy:true}],null,false,124757747)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }