<script setup>
import { computed } from "vue";

const props = defineProps({
  dialogOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:dialogOpen"]);

const innerDialogOpen = computed({
  get() {
    return props.dialogOpen;
  },
  set(newVal) {
    emit("update:dialogOpen", newVal);
  },
});
</script>

<template>
  <v-dialog v-model="innerDialogOpen" v-bind="$attrs">
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="primary--text"><slot name="title"></slot></span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="innerDialogOpen = false"
              v-on="on"
              v-bind="attrs"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="text-body-1">
        <slot name="text"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
