<script setup>
import LoadingArea from "@/components/utils/LoadingArea.vue";
import ViewPropertiesTable from "../view_properties/ViewPropertiesTable.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import Pagination from "@/components/utils/Pagination.vue";
import ViewProperty from "./ViewProperty.vue";
import GenerateRatesBillDialog from "./GenerateRatesBillDialog.vue";
import Dialog from "@/components/utils/Dialog.vue";
import { useFetchProperties } from "../properties_business_logic";
import { computed, ref } from "vue";
import { propertyRatesPayable } from "@/utils/functions";

const { propertyData, searchModel } = useFetchProperties();

const pages = {
  VIEW_PROPERTIES_TABLE: 0,
  VIEW_PROPERTY: 1,
};
const currentPage = ref(pages.VIEW_PROPERTIES_TABLE);
const goTo = {
  viewPropertiesTable() {
    currentPage.value = pages.VIEW_PROPERTIES_TABLE;
  },
  viewProperty(property) {
    (propertyToView.value = property),
      (currentPage.value = pages.VIEW_PROPERTY);
  },
};

const propertyToView = ref(null);
const propertyToViewNotEmpty = computed(() => {
  return !!propertyToView;
});

function onPaginationClicked(newPage) {
  propertyData.setNewPage(newPage);
}

function onViewPropertyClicked(property) {
  goTo.viewProperty(property);
}

const showGenerateRatesBillDialog = ref(false);

const propertyOfInterest = ref(false);

const ratesNotPayableDialogOpen = ref(false);

const ratesNotPayableErrorMsg = ref(null);

function onPayRatesClicked(property) {
  const ratesPayable = propertyRatesPayable(property);
  if (ratesPayable === true) {
    propertyOfInterest.value = property;
    showGenerateRatesBillDialog.value = true;
  } else {
    ratesNotPayableErrorMsg.value = ratesPayable;
    ratesNotPayableDialogOpen.value = true;
  }
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage">
      <v-tab-item :value="pages.VIEW_PROPERTIES_TABLE">
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Plots
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field
                class="background text-field"
                outlined
                dense
                v-model="searchModel"
                clearable
              />
            </LabelledField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LoadingArea :loading="propertyData.fetchingData">
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col cols="12">
                    <ViewPropertiesTable
                      :properties="propertyData.fetchedData"
                      :propertiesNotEmpty="propertyData.fetchedDataNotEmpty"
                      :loading="propertyData.fetchingData"
                      @viewPropertyClicked="onViewPropertyClicked($event)"
                      @payRatesClicked="onPayRatesClicked($event)"
                    >
                    </ViewPropertiesTable>
                  </v-col>
                </v-row>
              </v-container>
            </LoadingArea>
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-col cols="auto">
            <Pagination
              :totalNumberOfItems="propertyData.paginationData.total"
              :itemsPerPage="propertyData.paginationData.perPage"
              :currentPage="propertyData.paginationData.currentPage"
              @paginationClicked="onPaginationClicked"
            />
          </v-col>
        </v-row>

        <GenerateRatesBillDialog
          v-if="!!propertyOfInterest"
          :rates="propertyOfInterest.rates"
          :property="propertyOfInterest"
          :dialogOpen.sync="showGenerateRatesBillDialog"
        />

        <Dialog :dialogOpen.sync="ratesNotPayableDialogOpen" max-width="600">
          <template #title>
            <span class="error--text">Cannot Pay Property Rates</span>
          </template>
          <template #text
            ><span v-html="ratesNotPayableErrorMsg"></span
          ></template>
        </Dialog>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_PROPERTY">
        <v-row>
          <v-col cols="12">
            <ViewProperty
              v-if="propertyToViewNotEmpty"
              :property="propertyToView"
              showGoBack
              @goBackClicked="goTo.viewPropertiesTable"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
