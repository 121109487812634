<script setup>
import ViewItemSectionTitle from "@/components/utils/ViewItemSectionTitle.vue";
import { getFormattedMoney, arrayNotEmpty } from "@/utils/functions";
import { computed } from "vue";
import ActionsContainer from "@/components/utils/ActionsContainer.vue";
import ActionButton from "@/components/utils/ActionButton.vue";
import { actions } from "@/utils/constants";
import Table from "@/components/utils/Table.vue";

const props = defineProps({
  property: {
    type: Object,
    default: null,
  },
});

const propertyBills = computed(() => {
  const property = props.property;
  let bills = [];

  const propertyRegistrationBill = getPropertyRegistrationBills(property);
  const propertyTransferbill = getPropertyTransferBills(property);
  const propertyRatesBill = getPropertyRatesBills(property);
  const propertyBills = getPropertyBills(property) ?? [];
  const allBills = [
    propertyRegistrationBill,
    propertyTransferbill,
    propertyRatesBill,
    ...propertyBills,
  ];
  allBills.forEach((billOrNull) => {
    const billNotEmpty = !!billOrNull;
    if (billNotEmpty) {
      bills.push(billOrNull);
    }
  });

  return bills;
});

function getPropertyRegistrationBills(property) {
  const propertyRegistrationBill =
    property?.property_application_bills[0]?.bill ?? null;
  const propertyRegistrationInvoice =
    propertyRegistrationBill?.invoices[0] ?? null;
  const propertyHasRegistrationInvoice = !!propertyRegistrationInvoice;
  if (propertyHasRegistrationInvoice) {
    return {
      item: "Property Registration",
      billNumber: propertyRegistrationBill.bill_number,
      amount: propertyHasRegistrationInvoice.invoice_amount,
      billId: propertyRegistrationBill.id,
    };
  }
  return null;
}

function getPropertyTransferBills(property) {
  const propertyTransferApplication =
    property?.property_transfer_applications[0] ?? null;
  const propertyHasTransferApplication = !!propertyTransferApplication;
  if (propertyHasTransferApplication) {
    const propertyTransferBill =
      propertyTransferApplication.property_transfer_bills[0].bill;
    const propertyTransferInvoice = propertyTransferBill.invoices[0];
    return {
      item: "Property Transfer",
      billNumber: propertyTransferBill.bill_number,
      amount: propertyTransferInvoice.invoice_amount,
      billId: propertyTransferbill.id,
    };
  }
  return null;
}

function getPropertyRatesBills(property) {
  const propertyRatesBill = property?.rates?.bills[0] ?? null;
  const propertyHasRatesBill = !!propertyRatesBill;
  if (propertyHasRatesBill) {
    const propertyRatesInvoice = propertyRatesBill?.invoices[0] ?? null;
    return {
      item: "Property Rates",
      billNumber: propertyRatesBill.bill_number,
      amount: propertyRatesInvoice.invoice_amount,
      billId: propertyRatesBill.id,
      ...propertyRatesBill,
    };
  }
  return null;
}

function getPropertyBills(property) {
  const propertyBills =
    property?.bills?.map((bill) => {
      const invoiceAmount = bill?.invoices[0]?.invoice_amount ?? null;
      return {
        item: bill.remarks,
        billNumber: bill.bill_number,
        amount: invoiceAmount,
        billId: bill.id,
      };
    }) ?? null;

  return propertyBills;
}

// function getPropertySubdivisionBills(property){
//     const propertySubdivisionApplications = property?.property_subdivision_applications ?? null
//     const propertyHasSubdivisionApplication = !!propertySubdivisionApplications
//     if(propertyHasSubdivisionApplication){
//         return {
//             item: 'Property Sub-Division',
//             billNumber: '',
//             amount: propertyTransferInvoice.invoice_amount,
//             onPrintClicked() { }
//         }
//     }
// }
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="auto">
        <ViewItemSectionTitle>Bill</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Table
          :headers="[
            { text: 'Item', value: 'item' },
            { text: 'Bill Number', value: 'billNumber' },
            { text: 'Amount', value: 'amount' },
            { text: 'Download', value: 'download', action: true },
            { text: 'Pay', value: 'pay', action: true },
          ]"
          :items="propertyBills"
          emptyText="No Property Bills Found"
        >
          <template #item.amount="{ item: propertyBill }">
            {{ getFormattedMoney(propertyBill.amount) }}
          </template>

          <template #item.download="{ item: propertyBill }">
            <ActionButton
              :action="actions.downloadBill"
              inTable
              @click="
                $emit('printBillClicked', {
                  billId: propertyBill.billId,
                  billNumber: propertyBill.billNumber,
                })
              "
            />
          </template>

          <template #item.pay="{ item: propertyBill }">
            <ActionButton
              :action="actions.pay"
              inTable
              @click="$emit('payBillClicked', propertyBill)"
            />
          </template>
        </Table>

        <v-simple-table v-if="false">
          <template #default>
            <thead>
              <tr>
                <th>Item</th>
                <th>Bill Number</th>
                <th>Amount</th>
                <th class="text-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <template v-if="arrayNotEmpty(propertyBills)">
                <tr v-for="propertyBill in propertyBills">
                  <td>{{ propertyBill.item }}</td>
                  <td>{{ propertyBill.billNumber }}</td>
                  <td>{{ getFormattedMoney(propertyBill.amount) }}</td>
                  <td class="text-right">
                    <ActionsContainer>
                      <ActionButton
                        :action="actions.downloadBill"
                        inTable
                        @click="
                          $emit('printBillClicked', {
                            billId: propertyBill.billId,
                            billNumber: propertyBill.billNumber,
                          })
                        "
                      />
                      <ActionButton
                        :action="actions.pay"
                        inTable
                        @click="$emit('payBillClicked', propertyBill)"
                      />
                    </ActionsContainer>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4" align="center">No Bills</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
